<template>
    <div>
      <h1 class="title">Document Download Center</h1>
      <div class="dropdown-container">
        <div class="safe-select">
          <select v-model="selectedRange">
            <option v-for="(product, index) in products" :key="index" :value="product.value">{{ product.label }}</option>
          </select>
        </div>
      </div>
      <div class="dropdown-container">
        <div class="safe-select">
          <select v-model="selectedLanguage">
            <option v-for="(lang, index) in languages" :key="index" :value="lang.value">{{ lang.label }}</option>
          </select>
        </div>
      </div>
      <div class="safe-btn-container">
        <div class="safe-btn" @click="searchDocumentation">Search</div>
      </div>
      <div v-if="showNoDocsMessage" class="no-docs-message">
        No documentation available for this language
      </div>
      <div v-if="filteredDocumentations.length > 0" class="document-list">
        <div v-for="(doc, index) in filteredDocumentations" :key="index" class="document-item">
          <p>{{ doc.name }}</p>
          <button @click="downloadDocumentation(doc.url)">Download</button>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    data() {
      return {
        selectedRange: '',
        selectedLanguage: '',
        products: [
          { value: 'ps', label: 'SteriSpine PS' },
          { value: 'lc', label: 'SteriSpine LC' },
          { value: 'cc', label: 'SteriSpine CC' },
          { value: 'va', label: 'SteriSpine VA (Kyphoplasty & Sycamore)' }
        ],
        languages: [
          { value: '1', label: 'Français' },
          { value: '2', label: 'English' },
          { value: '3', label: 'Deutsch' },
          { value: '4', label: 'Español' },
          { value: '5', label: 'Italiano' },
          { value: '6', label: 'Português' },
          { value: '7', label: 'Nederlands' },
          { value: '8', label: 'Ελληνική' },
          { value: '9', label: 'Polski' }
        ],
        documentations: [
            // Toutes les gammes en FR
          { range: 'ps', language: '1', name: 'Essential - Technique chirurgicale', url: '/documentation/FR/PSSUP063-FR_A.pdf'},
          { range: 'ps', language: '1', name: 'Original - Technique chirurgicale', url: '/documentation/FR/PSSUP064-FR_A.pdf'},

          { range: 'lc', language: '1', name: 'Technique chirurgicale', url: '/documentation/FR/LC-Techop-US FR DE.pdf' },

          { range: 'cc', language: '1', name: 'Technique chirurgicale', url: '/documentation/FR/CC-Techop-US FR DE.pdf' },

          { range: 'va', language: '1', name: 'Kyphoplasty - Technique chirurgicale', url: '/documentation/FR/PSSUP089-FR_B.pdf' },
          { range: 'va', language: '1', name: 'Sycamore - Technique chirugicale', url: '/documentation/FR/VASUP003-1-FR_A.pdf' },

          // Toutes les gammes en EN
          { range: 'ps', language: '2', name: 'Essential - Surgical technique', url: '/documentation/EN/PSSUP064-EN_B.pdf'},
          { range: 'ps', language: '2', name: 'Original - Surgical technique', url: '/documentation/EN/PSSUP063-EN_B.pdf'},
          { range: 'ps', language: '2', name: 'Cypress - Brochure', url: '/documentation/EN/Cypress_PMC003_US_B_02022023.pdf'},
          { range: 'ps', language: '2', name: 'Oak - Brochure', url: '/documentation/EN/OAK_PMC002_US_B_02022023.pdf'},

          { range: 'lc', language: '2', name: 'Surgical technique', url: '/documentation/EN/LC-Techop-US FR DE.pdf' },
          { range: 'lc', language: '2', name: 'Cedar & Elm - Brochure', url: '/documentation/EN/Cedar-Elm_PMC004_US_A_02022023.pdf' },

          { range: 'cc', language: '2', name: 'Surgical technique', url: '/documentation/EN/CC-Techop-US FR DE.pdf' },
          { range: 'cc', language: '2', name: 'Walnut - Brochure', url: '/documentation/EN/Walnut_PMC005_US_A_02022023.pdf' },

          { range: 'va', language: '2', name: 'Kyphoplasty - Surgical technique', url: '/documentation/EN/PSSUP089-EN_B.pdf' },
          { range: 'va', language: '2', name: 'Sycamore - Surgical technique', url: '/documentation/EN/VASUP003-1-EN_A.pdf' },

          // Toutes les gammes en DE
          { range: 'ps', language: '3', name: 'Essential - Technischer Leitfaden', url: '/documentation/DE/PSSUP064-GE_A.pdf'},
          { range: 'ps', language: '3', name: 'Original - Technischer Leitfaden', url: '/documentation/DE/PS-Techop-UK FR DE.pdff'},

          { range: 'lc', language: '3', name: 'Technischer Leitfaden', url: '/documentation/DE/LC-Techop-US FR DE.pdf' },

          { range: 'cc', language: '3', name: 'Technischer Leitfaden', url: '/documentation/DE/CC-Techop-US FR DE.pdf' },

          { range: 'va', language: '3', name: 'Kyphoplasty - Technischer Leitfaden', url: '/documentation/DE/PSSUP089-GE_B.pdf' },
          { range: 'va', language: '3', name: 'Sycamore - Technischer Leitfaden', url: '/documentation/DE/VASUP003-1-GE_A.pdf' },

          // Toutes les gammes en ES
          { range: 'ps', language: '4', name: 'Essential - Técnica quirúrgica', url: '/documentation/ES/PSSUP064-ES_A.pdf'},
          { range: 'ps', language: '4', name: 'Original - Técnica quirúrgica', url: '/documentation/ES/PSSUP063-ES_A.pdf'},

          { range: 'lc', language: '4', name: 'Técnica quirúrgica', url: '/documentation/ES/LC-Techop-US IT ES PT.pdf' },

          { range: 'cc', language: '4', name: 'Técnica quirúrgica', url: '/documentation/ES/CC-Techop-US ES PT IT.pdf' },

          { range: 'va', language: '4', name: 'Kyphoplasty - Técnica quirúrgica', url: '/documentation/ES/PSSUP089-2-ES_B.pdf' },
          { range: 'va', language: '4', name: 'Sycamore - Técnica quirúrgica', url: '/documentation/ES/VASUP003-2-ES_A.pdf' },

          // Toutes les gammes en IT
          { range: 'ps', language: '5', name: 'Essential - Tecnica chirurgica', url: '/documentation/IT/PS-Techop-US IT ES PT.pdf'},
          { range: 'ps', language: '5', name: 'Original - Tecnica chirurgica', url: '/documentation/IT/PS-Techop-US IT ES PT.pdf'},

          { range: 'lc', language: '5', name: 'Tecnica chirurgica', url: '/documentation/IT/LC-Techop-US IT ES PT.pdf' },

          { range: 'cc', language: '5', name: 'Tecnica chirurgica', url: '/documentation/IT/CC-Techop-US ES PT IT.pdf' },

          { range: 'va', language: '5', name: 'Kyphoplasty - Tecnica chirurgica', url: '/documentation/IT/PSSUP089-2-IT_B.pdf' },
          { range: 'va', language: '5', name: 'Sycamore - Tecnica chirurgica', url: '/documentation/IT/VASUP003-2-IT_A.pdf' },

          // Toutes les gammes en PT
          { range: 'ps', language: '6', name: 'Essential - Técnica cirúrgica', url: '/documentation/PT/PS-Techop-US IT ES PT.pdf'},
          { range: 'ps', language: '6', name: 'Original - Técnica cirúrgica', url: '/documentation/PT/PS-Techop-US IT ES PT.pdf'},

          { range: 'lc', language: '6', name: 'Técnica cirúrgica', url: '/documentation/PT/LC-Techop-US IT ES PT.pdf' },

          { range: 'cc', language: '6', name: 'Técnica cirúrgica', url: '/documentation/PT/CC-Techop-US ES PT IT.pdf' },

          { range: 'va', language: '6', name: 'Kyphoplasty - Técnica cirúrgica', url: '/documentation/PT/PSSUP089-2-PT_B.pdf' },
          { range: 'va', language: '6', name: 'Sycamore - Tecnica chirurgica', url: '/documentation/PT/VASUP003-2-PT_A.pdf' },

          // Toutes les gammes en NL
          { range: 'ps', language: '7', name: 'Essential - Chirurgische techniek', url: '/documentation/NL/PS-Techop-US NL GR.pdf'},
          { range: 'ps', language: '7', name: 'Original - Chirurgische techniek', url: '/documentation/NL/PS-Techop-US NL GR.pdf'},

          { range: 'lc', language: '7', name: 'Chirurgische techniek', url: '/documentation/NL/LC-Techop-US NL GR.pdf' },

          { range: 'cc', language: '7', name: 'Chirurgische techniek', url: '/documentation/NL/CC-Techop-US NL GR.pdf' },

          { range: 'va', language: '7', name: 'Kyphoplasty - Chirurgische techniek', url: '/documentation/NL/PSSUP089-3-NL_B.pdf' },
          { range: 'va', language: '7', name: 'Sycamore - Chirurgische techniek', url: '/documentation/NL/VASUP003-3-NL_A.pdf' },

          // Toutes les gammes en GR
          { range: 'ps', language: '8', name: 'Essential - Χειρουργική τεχνική', url: '/documentation/GR/PS-Techop-US NL GR.pdf'},
          { range: 'ps', language: '8', name: 'Original - Χειρουργική τεχνική', url: '/documentation/GR/PS-Techop-US NL GR.pdf'},

          { range: 'lc', language: '8', name: 'Χειρουργική τεχνική', url: '/documentation/GR/LC-Techop-US NL GR.pdf' },

          { range: 'cc', language: '8', name: 'Χειρουργική τεχνική', url: '/documentation/GR/CC-Techop-US NL GR.pdf' },

          { range: 'va', language: '8', name: 'Kyphoplasty - Χειρουργική τεχνική', url: '/documentation/GR/PSSUP089-3-GK_B.pdf' },
          { range: 'va', language: '8', name: 'Sycamore - Χειρουργική τεχνική', url: '/documentation/GR/VASUP003-3-GK_A.pdf' },

          // Toutes les gammes en PL
          { range: 'ps', language: '9', name: 'Essential - Technika chirurgiczna', url: '/documentation/PL/PS-Techop-US PL.pdf'},
          { range: 'ps', language: '9', name: 'Original - Technika chirurgiczna', url: '/documentation/PL/PS-Techop-US PL.pdf'},

          { range: 'lc', language: '9', name: 'Technika chirurgiczna', url: '/documentation/PL/LC-Techop-US PL DK.pdf' },

          { range: 'va', language: '9', name: 'Kyphoplasty - Technika chirurgiczna', url: '/documentation/PL/PSSUP089-3-PL_B.pdf' },
          { range: 'va', language: '9', name: 'Sycamore - Technika chirurgiczna', url: '/documentation/PL/VASUP003-3-PL_A.pdf' }
        ],
        filteredDocumentations: [], // New array to store filtered documents
        showNoDocsMessage: false // Add a flag to control the message visibility
      };
    },
    methods: {
      searchDocumentation() {
        this.filteredDocumentations = [];
        this.documentations.forEach(doc => {
            if (doc.range === this.selectedRange && doc.language === this.selectedLanguage) {
                this.filteredDocumentations.push(doc)
            }
        });
        // Update the flag based on the search result
      this.showNoDocsMessage = this.filteredDocumentations.length === 0;
    },
    downloadDocumentation(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = ''; // This will set the downloaded file name to the one specified in the URL
      link.click();
    }
  }
};
</script>

<style scoped>
/* Inserted CSS styles */
.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 10%;
  color: #80bd01;
}

.dropdown-container {
  display: flex;
  padding: 0 20%;
  padding-bottom: 20px;
  justify-content: space-between;
}

.safe-select {
  width: 100%;
}

.safe-btn-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.no-docs-message {
  font-size: 18px;
  text-align: center;
  color: #ff0505;
  margin-top: 20px;
}

.safe-select select {
  background: #000;
  border: 1px solid #80bd01;
  color: #80bd01;
  padding: 0 20px;
  width: 100%;
  height: 40px;
  font-size: 20px;
}

.safe-btn {
  color: #80bd01;
  border: 1px solid #80bd01;
  font-size: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  width: 100px;
}

.safe-btn:hover {
  background: #80bd01;
  color: #fff;
}

.document-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.document-item {
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 5%;
}

.document-item button {
  background-color: #80bd01;
  color: white;
  font-size: 20px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.document-item button:hover {
  background-color: #5a8c01;
}

.document-item p {
  font-size: 25px;
}
</style>